<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-stores' }">{{
            $t("STORES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton v-on:onDelete="onDelete()"></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingStore"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm>
            <v-text-field
              :value="form.name"
              :label="$t('STORE')"
              outlined
              disabled
              readonly
            ></v-text-field>
          </b-col>
        </b-row>
        <v-tabs
          background-color="white"
          color="primary accent-4"
          centered
          icons-and-text
        >
          <v-tab> {{ $t("DESCRIPTION") }}<v-icon>mdi-note-text</v-icon></v-tab>

          <v-tab>
            {{ $t("SUPPLIERS") }}
            <v-icon>mdi-truck-fast</v-icon></v-tab
          >

          <v-tab-item :transition="false" :reverse-transition="false">
            <b-form>
              <FormBuilder
                v-if="!firstLoader"
                :form="form"
                :schemaJson="schemaJson"
                :v="$v"
              ></FormBuilder>
              <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
            </b-form>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <CheckboxList
                  :heading="$t('SUPPLIERS')"
                  :items="suppliers"
                  :selectedItems.sync="form.suppliers"
                  :modelName="'suppliers'"
                  :firstLoader="firstLoader"
                  :checkboxLoader="isLoadingSharedSupplier"
                  :sm="4"
                >
                </CheckboxList>
                <SkeletonLoaderCheckboxes
                  :visible="firstLoader"
                ></SkeletonLoaderCheckboxes>
              </b-col>
            </b-row>
          </v-tab-item>
        </v-tabs>
        <v-overlay :value="!firstLoader && isLoadingStore">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton v-on:onDelete="onDelete()"></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingStore"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_STORE,
  UPDATE_STORE,
  DELETE_STORE
} from "@/modules/store/store/store.module";

//City Module
import { FETCH_SUPPLIERS_BASIC } from "@/core/services/store/shared_supplier.module";

import { FETCH_CITIES } from "@/modules/city/store/city.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import CheckboxList from "@/view/content/forms/components/CheckboxList.vue";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      store: {},
      suppliers: [],
      cities: [],
      citiesParams: {
        page: 1,
        itemsPerPage: 50,
        query: ""
      },
      isEdit: true
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    CheckboxList
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stores", route: { name: "list-stores" } },
      { title: "Edit Store" }
    ]);
    vm.getStore();
    vm.getCities();
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingStore", "isLoadingSharedSupplier", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    suppliersParams() {
      let suppliersParams = {};
      return suppliersParams;
    },
    storeCityName() {
      let vm = this;
      if (_.size(vm.form) > 0) {
        return vm.store.city;
      }
      return "";
    },
    schemaJson() {
      return {
        fields: [
          {
            type: "switch",
            groupId: "item-isRecipe-group",
            id: "item-isRecipe-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isRecipe-live-feedback",
            colSm: "auto",
            divider: true,
            i18n: {
              label: "IS_WAREHOUSE",
              placeholder: "IS_WAREHOUSE"
            },
            validations: {
              required: true
            },
            model: "isWarehouse"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-name-input",
            groupId: "store-name-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "store-city-group",
            id: "store-city-input",
            feedback_id: "city-live-feedback",
            divider: true,
            clearable: false,
            i18n: {
              label: "CITY",
              placeholder: "CITY",
              validation: "VALID_CITY"
            },
            options: this.cities,
            validations: {
              required: true
            },
            model: "cityId"
          },

          {
            type: "input",
            inputType: "text",
            id: "store-address-input",
            groupId: "store-address-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "ADDRESS",
              placeholder: "ADDRESS"
            },
            validations: {},
            translatable: false,
            model: "address"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-code-input",
            groupId: "store-code-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "CODE",
              placeholder: "CODE",
              validation: "CODE"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "code"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-echo-name-input",
            groupId: "store-echo-name-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: true,
            i18n: {
              label: "ECHO_NAME",
              placeholder: "ECHO_NAME",
              validation: "ECHO_NAME"
            },
            validations: {},
            translatable: false,
            model: "echoName"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-echo-id-input",
            groupId: "store-echo-id-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "ECHO_ID",
              placeholder: "ECHO_ID",
              validation: "ECHO_ID"
            },
            validations: {},
            translatable: false,
            model: "echoId"
          },
          {
            type: "input",
            inputType: "text",
            id: "store-echo-code-input",
            groupId: "store-echo-code-group",
            required: "required",
            feedback_id: "store-live-feedback",
            divider: false,
            i18n: {
              label: "ECHO_CODE",
              placeholder: "ECHO_CODE",
              validation: "ECHO_CODE"
            },
            validations: {},
            translatable: false,
            model: "echoCode"
          }
        ]
      };
    }
  },
  methods: {
    getStore() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_STORE, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.store = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.store).then(
              () => {
                vm.$set(vm.form, "cityId", vm.store.cityId);
                vm.$set(vm.form, "suppliers", vm.store.suppliers);
                vm.$set(vm.form, "isWarehouse", vm.store.isWarehouse);
                vm.getSuppliers();
                vm.prepareChangesCheck();
              }
            );
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
    },
    getSuppliers() {
      let vm = this;
      let params = this.suppliersParams;
      let apiParams = vm.transformUrlParams(params);
      vm.suppliersLoader = true;
      this.$store
        .dispatch(FETCH_SUPPLIERS_BASIC, apiParams)
        .then(data => {
          this.$set(this, "suppliers", data.data);
        })
        .catch(response => {
          console.log(response);
        })
        .finally(() => {
          vm.suppliersLoader = false;
        });
    },
    getCities(search, loading = function() {}) {
      let vm = this;
      let params = this.citiesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_CITIES, apiParams)
        .then(data => {
          vm.cities = data.data.items;
          vm.totalCities = data.data.totalItemsCount;
        })
        .catch(response => {
          console.log(response);
        })
        .finally(() => {
          loading(false);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_STORE, { id: vm.store.id, payload: this.form })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            if (next == "continue") {
              //New
              // this.$router.push({
              //   name: "edit-store",
              //   params: { id: itemEdit.id }
              // });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-store" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-stores" });
            }
          });
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_STORE, vm.store.id)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });

              vm.prepareChangesCheck().then(() => {
                vm.$router.push({ name: "list-stores" });
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
